<template>
  <v-app >
      <v-main class="blue lighten-4">
          <v-container fill-height fluid>
            <v-row 
                align="center"
                justify="center"
                style="max-width: 600px"
                class="mx-auto"
                >
                <v-col v-if="enviado">
                    <v-card>
                        <div class="blue-grey text-center py-4 white--text">
                            <v-img class="mx-auto" max-width="100" src="@/assets/logo.png"></v-img> 
                            <div class="text-h6">CLINICA GOOD HOPE</div>
                        </div>
                        <v-card-text>
                            <div class="text-center ma-4 text-h5">
                                La contraseña ha sido actualizada. puede iniciar sesión para seguir usando el sistema.
                            </div>
                            <v-btn color="info" class="mx-auto" to="/login">Ir al inicio de sesión</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col v-else>
                    <div style="height: 24px;" class="d-block d-md-none"></div>
                    <v-card elevation="15" >
                        <div class="blue-grey text-center py-4 white--text">
                            <v-img class="mx-auto" max-width="100" src="@/assets/logo.png"></v-img> 
                            <div class="text-h6">CLINICA GOOD HOPE</div>
                        </div>
                        <div v-if="tramite">
                            <v-progress-linear :indeterminate="true"></v-progress-linear>
                        </div>
                        <v-card-text class="text-center">
                            <div class="text-h4">Actualizar contraseña</div>
                        </v-card-text>
                        <v-card-text>
                            <v-text-field
                                name="password"
                                type="password"
                                label="Nueva contraseña"
                                v-model="sena1"
                                outlined
                                :disabled="tramite"
                                @keypress.enter="validar()"
                            ></v-text-field>
                            <v-text-field
                                name="password"
                                type="password"
                                label="Reingrese nueva contraseña"
                                outlined
                                @keypress.enter="validar()"
                                v-model="sena2" 
                                :disabled="tramite"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-text>
                            <div class="text-h6">Criterios</div>
                            <div class="ml-4" :class="[iguales?'green--text':'red--text']">Se ha repedido bien la nueva contraseña</div>
                            <div class="ml-4" :class="[letas?'green--text':'red--text']">Solo letras y/o números</div>
                            <div class="ml-4" :class="[minimo?'green--text':'red--text']">Mínimo 4 caracteres</div>
                        </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row>
                                <v-col cols="12" md="6"><v-btn outlined to="/login">Volver al inicio</v-btn></v-col>
                                <v-col cols="12" md="6"><v-btn :disabled="tramite || !(iguales&&letas&&minimo)" color="primary" @click="validar()">
                                    Actualizar contraseña
                                </v-btn></v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
          </v-container>
      </v-main>
  </v-app>
</template>

<script>
import securityService from '@/service/hopeSeguridad'
export default {
    data(){return{
        enviado:false,
        tramite: false,
        sena1:'',
        sena2:'',
        formulario:{
            id:'',
            password:'',

        },

    }},
    methods:{
        validar(){
            if(this.iguales&&this.letas&&this.minimo){
                this.formulario.id = this.$route.query.recover;
                this.formulario.password = this.sena1;
                securityService.updatePassword(this.formulario)
                .then(()=>{
                    this.enviado = true;
                    this.tramite = false;
                })
                .catch(()=>{
                    alert("error al comunicarnos con nuestros servidores")
                    this.tramite = false;
                    this.enviado = false;
                })
            }
        }
    },
    computed:{
        letas: function(){
            const patron = /^[\w-_.]*$/
            return patron.test(this.sena1)
        },
        minimo:  function(){
            const patron = /^.{4,}$/
            return patron.test(this.sena1)
        },
        iguales:  function(){
            return this.sena1 === this.sena2;
        }
    }

}
</script>

<style>

</style>